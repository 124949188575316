import React from 'react';
import { Box } from '@quarkly/widgets';

const SvgIcon = ({
	svg,
	children,
	...props
}) => <Box
	mask-image={`url(${svg})`}
	mask-repeat="no-repeat"
	mask-position="center"
	min-height="5px"
	min-width="5px"
	background="black"
	{...props}
/>;

Object.assign(SvgIcon, {
	title: 'SvgIcon',
	propInfo: {
		svg: {
			weight: 1,
			control: "image"
		}
	}
});
export default SvgIcon;