import React, { useContext } from 'react';
import atomize from "@quarkly/atomize";
import { LinkBox } from '@quarkly/widgets';
import { DropdownContext } from './Dropdown';

const DropdownClose = ({
	children,
	...props
}) => {
	const {
		setOpen
	} = useContext(DropdownContext);
	return <LinkBox onClick={() => setOpen(false)} {...props}>
		    
		{children}
		  
	</LinkBox>;
};

export default atomize(DropdownClose)({
	name: "DropdownClose",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "DropdownClose — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});