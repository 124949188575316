import React, { useState, useEffect, useRef, createContext } from 'react';
import { Box, Placeholder, useConstructorMode } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components'; // import useResizeObserver from '@react-hook/resize-observer';

import { useTheme } from 'styled-components'; // ==========================

const isPlaceholder = child => child && child.props && child.props.text === 'child placeholder';

const isOverride = child => child && child.props && typeof child.props.slot === 'string' && child.props.slot.length > 0;

function isEmptyChildren(children) {
	const childrenArray = React.Children.toArray(children);
	return !childrenArray.some(child => child && !isPlaceholder(child) && !isOverride(child));
} // =========================


const overrides = {
	Menu: {
		kind: 'Box',
		props: {
			position: "absolute",
			"z-index": 1,
			padding: "12px 16px",
			"min-width": "160px",
			"background-color": "white",
			"box-shadow": "0 0 7px 0 rgba(0,0,0,.2)"
		}
	}
};
const propInfo = {
	activationMode: {
		title: 'Activation Mode',
		description: {
			en: 'Choose how to activate the dropdown: hover or click'
		},
		control: 'select',
		variants: ['hover', 'click'],
		category: 'Main',
		weight: 1
	},
	showMenuDev: {
		title: 'Show menu',
		category: 'DEV',
		control: 'checkbox'
	}
};
const defaultProps = {
	activationMode: 'hover'
};

function useBreakpoint() {
	const {
		breakpoints
	} = useTheme(); // Инициализация состояния для ширины и высоты

	const [breakpoint, setBreakpoint] = useState();

	function determineBreakpoint(width) {
		if (width <= breakpoints.sm[0].value) {
			return 'sm';
		} else if (width <= breakpoints.md[0].value) {
			return 'md';
		} else if (width <= breakpoints.lg[0].value) {
			return 'lg';
		} else {
			return '';
		}
	} // Эффект для подписки на событие изменения размера


	useEffect(() => {
		const handleResize = () => {
			const newWidth = document.documentElement.clientWidth;
			setBreakpoint(determineBreakpoint(newWidth));
		}; // Подписка на событие resize


		window.addEventListener('resize', handleResize);

		if (typeof document !== 'undefined') {
			handleResize();
		} // Очистка слушателя при размонтировании компонента


		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []); // Возвращаем текущие размеры

	return breakpoint;
}

export const DropdownContext = createContext(null);

const Dropdown = ({
	showMenuDev,
	...props
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const {
		override,
		children,
		ChildPlaceholder,
		rest
	} = useOverrides(props, overrides);
	const dropdownRef = useRef(null);
	const mode = useConstructorMode();
	const size = useBreakpoint();
	const activationMode = ['sm', 'md', 'lg'].includes(size) ? 'click' : 'hover';
	useEffect(() => {
		const handleClickOutside = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsHovered(false);
			}
		};

		if (activationMode === 'click') {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [activationMode]);

	const handleMouseEnter = () => activationMode === 'hover' && setIsHovered(true);

	const handleMouseLeave = () => activationMode === 'hover' && setIsHovered(false);

	const handleClick = () => activationMode === 'click' && setIsHovered(true);

	const stopPropagation = event => event.stopPropagation();

	const showMenu = mode === 'constructor' && showMenuDev || isHovered;
	const menuStyle = showMenu ? {
		display: 'block'
	} : {
		display: 'none'
	};
	const contextValue = {
		setOpen: setIsHovered
	};
	return <DropdownContext.Provider value={contextValue}>
		      
		<Box
			ref={dropdownRef}
			position="relative"
			display="inline-block"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleClick}
			{...rest}
		>
			        
			<Box {...override("Container", showMenu && "Container :hovered")}>
				          
				{children}
				          
				{isEmptyChildren(children) && <Placeholder message="Drop content here" />}
				        
			</Box>
			        
			<Box {...override("Menu")} style={menuStyle} onClick={stopPropagation}>
				          
				{isEmptyChildren(override("Menu").children) && <Placeholder message="Drop content here" />}
				          
				<ChildPlaceholder slot={"Menu"} />
				        
			</Box>
			      
		</Box>
		    
	</DropdownContext.Provider>;
};

Object.assign(Dropdown, {
	overrides,
	propInfo,
	defaultProps
});
export default Dropdown;