import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image, List, Link, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"optical-device-fabrication"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px" z-index="1">
			<Override slot="linkBox7" color="white" />
			<Override slot="linkBox13" color="--accentOrange" />
			<Override slot="linkBox14" display="none" />
			<Override slot="button" type="link" href="/memory-crystal">
				Order 5D Memory Crystal™
			</Override>
			<Override slot="text" font="600 27px Epilogue">
				SPhotonix
			</Override>
			<Override slot="text8" font="20px/24px Epilogue">
				Company
			</Override>
			<Override slot="text15" font="20px/24px Epilogue" />
			<Override slot="text17" font="20px/24px Epilogue" sm-font="--bodyText17Medium">
				Contact
			</Override>
			<Override slot="image" height="50px" />
			<Override slot="linkBox8" href="/data-storage-innovation" />
			<Override slot="box" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			width="100%"
			height="auto"
			padding-top="120px"
			padding-bottom="64px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			padding="120px 16px 64px 16px"
			md-padding="60px 16px 24px 16px"
			sm-overflow-x="hidden"
			sm-max-width="100%"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				grid-gap="16px"
				display="flex"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="center"
					color="#080808"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					md-font="600 32px/30px Epilogue"
				>
					Optical Devices
				</Text>
				{"    "}
				<Text
					text-align="center"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="747px"
					max-width="100%"
					margin="0px 0px 0px 0px"
				>
					SPhotonix specialises in the fabrication of advanced optical components, leveraging proprietary nanostructuring technology that sets new industry standards for performance, cost efficiency, and adaptability. 
Our expertise in developing polarization prisms and other optical devices makes us a go-to partner for industries ranging from microscopy to laser fusion and beyond.
				</Text>
				{"  "}
			</Box>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="0px 16px 80px 16px"
			flex-direction="column"
			justify-content="center"
			align-items="center"
			grid-gap="100px"
			display="flex"
			md-padding="0px 16px 80px 16px"
			background="#000000 url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/technology-bg.webp?v=2024-10-03T21:04:17.534Z)"
			sm-overflow-x="hidden"
			sm-max-width="100%"
		>
			{"  "}
			<Image
				width="1350px"
				src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20:16:20.182Z"
				max-width="100%"
				sm-width="100%"
				sm-max-width="100%"
				srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20%3A16%3A20.182Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20%3A16%3A20.182Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20%3A16%3A20.182Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20%3A16%3A20.182Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20%3A16%3A20.182Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20%3A16%3A20.182Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00043.JPG?v=2024-09-26T20%3A16%3A20.182Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
			/>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="50px 16px 0px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="50px 16px 0px 16px"
			md-display="flex"
			md-grid-row-gap="32px"
			sm-overflow-x="hidden"
			sm-max-width="100%"
		>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-height="auto"
				md-width="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				max-width="1348px"
				margin="0px auto 0px auto"
				sm-max-width="100%"
			>
				{"    "}
				<Box
					min-height="100px"
					md-width="100%"
					md-height="250px"
					flex="1 1 0%"
					height="530px"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12:52:31.048Z"
						md-width="100%"
						md-height="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						sm-max-width="100%"
						sm-height="auto"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12%3A52%3A31.048Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12%3A52%3A31.048Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12%3A52%3A31.048Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12%3A52%3A31.048Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12%3A52%3A31.048Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12%3A52%3A31.048Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/WhatsApp%20Image%202024-09-25%20at%2000.07.24.jpeg?v=2024-09-25T12%3A52%3A31.048Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				{"    "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="flex"
					md-height="auto"
					grid-row-gap="30px"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#080808"
							font="700 42px/50px Epilogue"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							Superior Polarization Prisms for Advanced Applications
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							Our patented technology allows us to fabricate DIC, Nomarski, and polarization prisms that outperform traditional optics in both performance and cost-effectiveness.{" "}
							<br />
							By utilizing our nanostructuring techniques, we offer unparalleled precision in polarization control, which is critical in high-end microscopy, astronomy and laser applications.
							<br />
							Key advantages of our polarization prisms include:
						</Text>
						{"      "}
					</Box>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="disc"
						as="ul"
						font="400 21px Epilogue"
						align-self="stretch"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="--bodyText21Regular"
							padding="0px 0px 10px 0px"
							color="#212121"
							sm-font="--bodyText17Regular"
						>
							Enhanced polarization control for clearer imaging and more accurate light direction.
						</Text>
						<Text margin="0px 0px 10px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Reduced light scattering, resulting in higher resolution in applications such as microscopy.
						</Text>
						<Text margin="0px 0px 0px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Customizability for specific use cases, enabling tailor-made solutions for diverse industries.
						</Text>
					</List>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
			<Box align-self="stretch" height="1px" background="#B1B1B1" />
		</Box>
		<Box
			width="100%"
			height="auto"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="40px 16px 0px 16px"
			md-display="flex"
			md-grid-row-gap="32px"
			padding="100px 16px 100px 16px"
			sm-overflow-x="hidden"
			sm-max-width="100%"
		>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-width="auto"
				md-height="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				max-width="1348px"
				margin="0px auto 0px auto"
				sm-max-width="100%"
			>
				{"    "}
				<Box
					min-height="100%"
					md-width="100%"
					md-height="250px"
					flex="1 1 0%"
					md-order="-1"
					height="530px"
					sm-max-width="100%"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20:17:54.234Z"
						md-height="100%"
						md-width="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						sm-height="auto"
						sm-max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20%3A17%3A54.234Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20%3A17%3A54.234Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20%3A17%3A54.234Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20%3A17%3A54.234Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20%3A17%3A54.234Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20%3A17%3A54.234Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00347.png?v=2024-09-26T20%3A17%3A54.234Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				{"    "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="flex"
					md-height="auto"
					order="-1"
					grid-row-gap="30px"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#080808"
							font="700 42px Epilogue"
							line-height="50px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							Pioneering Laser Optics
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							We are actively developing cutting-edge polarization control optics for a variety of optical applications.{" "}
							<br />
							Our proprietary nanostructuring methods provide significant advantages over traditional approaches:
						</Text>
						{"      "}
					</Box>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="disc"
						as="ul"
						font="400 21px Epilogue"
						align-self="stretch"
						sm-margin="0px 0px 20px 0px"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="--bodyText21Regular"
							padding="0px 0px 10px 0px"
							white-space="normal"
							color="#212121"
							sm-font="--bodyText17Regular"
						>
							Material superiority: We use fused silica, a highly durable and cost-effective material that offers superior performance over other materials traditionally used in optical systems.
						</Text>
						<Text margin="0px 0px 10px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Cost-effective production: Our nanostructuring process is more efficient, reducing phase variations in laser beams while keeping production costs low.
						</Text>
						<Text margin="0px 0px 0px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Scalability: Our solutions are designed for future laser energy systems, with potential for adoption in large-scale laser applications globally.
						</Text>
					</List>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="0px 16px 100px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="50px 16px 24px 16px"
			md-display="flex"
			md-grid-row-gap="40px"
			background="#000000 url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/technology-bg.webp?v=2024-10-03T21:04:17.534Z) 0% 0%/cover"
			sm-max-width="100%"
			sm-overflow-x="hidden"
		>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-width="auto"
				md-height="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				margin="0px auto 0px auto"
				max-width="1348px"
				sm-max-width="100%"
			>
				{"    "}
				<Box
					min-height="100px"
					md-width="100%"
					md-height="250px"
					flex="1 1 0%"
					height="530px"
					sm-max-width="100%"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20:19:00.961Z"
						md-height="100%"
						md-width="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						sm-height="auto"
						sm-max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20%3A19%3A00.961Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20%3A19%3A00.961Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20%3A19%3A00.961Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20%3A19%3A00.961Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20%3A19%3A00.961Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20%3A19%3A00.961Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/DSC00075.JPG?v=2024-09-26T20%3A19%3A00.961Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				{"    "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="inline-flex"
					md-height="auto"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#ffffff"
							font="700 42px Epilogue"
							line-height="50px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							Proprietary Technology & Intellectual Property
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#ffffff"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							Our continuous innovation in optical device fabrication is underpinned by strong intellectual property. We have developed unique methods that not only enhance the performance of our prisms but also reduce manufacturing costs, making high-end optical components more accessible. Our solutions are designed to outperform traditional products in performance while offering significant economic advantages.
						</Text>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="0px 16px 100px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="50px 16px 24px 16px"
			md-display="flex"
			md-grid-row-gap="40px"
			sm-padding="20px 16px 24px 16px"
			sm-overflow-x="hidden"
			sm-max-width="100%"
		>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-width="auto"
				md-height="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				margin="100px auto 0px auto"
				max-width="1348px"
				sm-margin="50px auto 0px auto"
			>
				{"        "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="flex"
					md-height="auto"
					grid-row-gap="30px"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#080808"
							font="700 42px Epilogue"
							line-height="50px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							A Versatile Approach with Broad Applications
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							Our expertise in polarization optics extends beyond microscopy and laser optics. Our technology is adaptable and scalable for a variety of high-tech industries:
							<br />
						</Text>
						{"      "}
					</Box>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="disc"
						as="ul"
						font="400 21px Epilogue"
						align-self="stretch"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="--bodyText21Regular"
							padding="0px 0px 10px 0px"
							color="#212121"
							sm-font="--bodyText17Regular"
						>
							Imaging Systems: Polarization control devices designed for cutting-edge imaging systems.
						</Text>
						<Text margin="0px 0px 10px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Sensing: High-performance prisms for sensitive detection in scientific and industrial applications.
						</Text>
						<Text margin="0px 0px 0px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Telecommunications: Precision polarization optics for advanced communication systems.
						</Text>
					</List>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
		</Box>
		<Box
			align-self="stretch"
			height="1px"
			background="#B1B1B1"
			width="1348px"
			margin="0px auto 0px auto"
			sm-overflow-x="hidden"
			sm-max-width="100%"
		/>
		<Box
			width="100%"
			height="auto"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="40px 16px 0px 16px"
			md-display="flex"
			md-grid-row-gap="32px"
			padding="100px 16px 100px 16px"
			sm-padding="40px 16px 30px 16px"
			sm-max-width="100%"
			sm-overflow-x="hidden"
		>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-width="auto"
				md-height="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				{"        "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="flex"
					md-height="auto"
					order="-1"
					grid-row-gap="30px"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#080808"
							font="700 42px Epilogue"
							line-height="50px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							Why Partner with SPhotonix?
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							SPhotonix is committed to pushing the frontiers of optical technology, ensuring that our partners receive not only the best performance but also the most cost-efficient solutions on the market.
						</Text>
						{"      "}
					</Box>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="disc"
						as="ul"
						font="400 21px Epilogue"
						align-self="stretch"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="--bodyText21Regular"
							padding="0px 0px 10px 0px"
							white-space="normal"
							color="#212121"
							sm-font="--bodyText17Regular"
						>
							Proprietary IP: Advanced methods and technologies that give us a competitive edge.
						</Text>
						<Text margin="0px 0px 10px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Cost-effective production: High-performance optical devices at lower costs.
						</Text>
						<Text margin="0px 0px 10px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Custom solutions: We work closely with clients to develop optical devices tailored to their specific needs.
						</Text>
						<Text margin="0px 0px 0px 0px" font="--bodyText21Regular" color="#212121" sm-font="--bodyText17Regular">
							Versatile applications: From microscopes to cutting-edge laser systems, our optical devices deliver superior performance across various industries.
						</Text>
					</List>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
		</Box>
		<Box
			align-self="stretch"
			height="1px"
			background="#B1B1B1"
			width="1348px"
			margin="0px auto 0px auto"
			sm-overflow-x="hidden"
			sm-max-width="100%"
		/>
		<Box
			width="100%"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="inline-flex"
			md-display="flex"
			md-padding="56px 16px 24px 16px"
			md-height="min-content"
			md-grid-row-gap="64px"
			padding="100px 16px 24px 16px"
			background="white"
			sm-max-width="100%"
			sm-overflow-x="hidden"
		>
			{"    "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="80px"
				display="inline-flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-grid-row-gap="24px"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					flex="1 1 0"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="inline-flex"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					md-height="fit-content"
					md-align-items="center"
					md-display="flex"
					md-flex-direction="column"
					md-justify-content="center"
					md-flex-wrap="no-wrap"
					md-grid-column-gap="0px"
					md-grid-row-gap="8px"
					md-max-width="460px"
				>
					{"      "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
					>
						{"        "}
						<Text
							color="#080808"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px"
							md-font="600 28px/32px Epilogue"
							md-width="100%"
							text-fill-color="transparent"
							style={{
								"-webkit-text-fill-color": "transparent"
							}}
							background="linear-gradient(96.04deg, #080808 -0.01%, #FC0D1D 42.34%)"
							background-clip="text"
							max-width="100%"
							width="350px"
						>
							Get in touch...
						</Text>
						{"      "}
					</Box>
					{"      "}
					<Text
						align-self="stretch"
						opacity={0.8}
						color="#080808"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-width="100%"
						md-font="400 17px/26px Epilogue"
					>
						<Strong>
							<Link href="mailto:info@sphotonix.com?Web Enquiry: ">
								info@sphotonix.com
							</Link>
						</Strong>
					</Text>
					{"    "}
				</Box>
				{"    "}
				<Components.MakeForm
					md-width="100%"
					max-width="460px"
					sm-max-width="100%"
					action="https://hook.us2.make.com/vmpi9qw5hahp757juwr9sws9xf0n4k2u"
					method="post"
				>
					<Override
						slot="Form"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="inline-flex"
					/>
					<Override
						slot="Content"
						display="flex"
						flex-direction="column"
						flex-wrap="no-wrap"
						grid-row-gap="16px"
					/>
					<Override slot="Error Text" />
					<Override slot="Success Text" font="--bodyText17Regular" />
					{"      "}
					<Text
						align-self="stretch"
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-font="400 17px/26px Epilogue"
					>
						Stay ahead with the latest breakthroughs in optical nanotechnology! Discover how SPhotonix is revolutionizing data storage and optical innovation. Join our community for exclusive insights and updates!
					</Text>
					{"      "}
					<Components.MfInput name="name" type="text" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Name"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					<Components.MfInput name="email" type="email" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Email"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					{"      "}
					<Button
						width="100%"
						padding-left="24px"
						padding-right="24px"
						padding-top="16px"
						padding-bottom="16px"
						background="#EB1E1E"
						border-radius="8px"
						grid-gap="10px"
						color="white"
						font="600 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						text-align="center"
						type="submit"
					>
						Subscribe to Newsletter
					</Button>
					{"    "}
				</Components.MakeForm>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				md-display="flex"
				md-grid-row-gap="32px"
				md-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					align-self="stretch"
					height="1px"
					background="#B1B1B1"
					md-width="100%"
					md-height="1px"
				/>
				{"    "}
				<Box
					align-self="stretch"
					justify-content="space-between"
					align-items="center"
					grid-gap="32px"
					display="flex"
					md-width="100%"
					md-height="fit-content"
					md-display="flex"
					md-flex-direction="row"
					md-flex-wrap="wrap"
					md-align-content="space-around"
					md-align-items="center"
					md-justify-content="space-between"
					flex-direction="row"
					md-grid-row-gap="16px"
				>
					{"      "}
					<Text
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
					>
						©2024 SPhotonix. All rights reserved.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="center"
						grid-column-gap="32px"
						md-order="-1"
					>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Privacy Policy
						</Text>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Cookies
						</Text>
					</Box>
					{"                "}
				</Box>
				{"  "}
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});