import React, { useEffect, useRef, useReducer, useCallback } from 'react';
import { Box, Button, Image, Placeholder, useConstructorMode } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
const overrides = {
	Button: {
		kind: 'Button',
		props: {
			children: 'Toggle',
			'focus-box-shadow': 'none'
		}
	},
	Content: {
		kind: 'Box',
		props: {
			'min-height': '0',
			overflow: 'hidden'
		}
	},
	Wrapper: {
		kind: 'Box',
		props: {
			'margin-top': '8px',
			'min-height': '0',
			overflow: 'hidden'
		}
	},
	'Wrapper :open': {
		kind: 'Box',
		props: {
			visibility: 'visible'
		}
	},
	'Wrapper :close': {
		kind: 'Box',
		props: {
			visibility: 'hidden',
			height: 0
		}
	},
	'Wrapper :collapsing': {
		kind: 'Box',
		props: {
			height: '0'
		}
	},
	'Pre-Button': {
		kind: 'Box'
	}
};
const defaultProps = {
	duration: '0.5s',
	animFunction: 'linear'
};
const propInfo = {
	duration: {
		title: {
			en: 'Animation duration',
			ru: 'Длительность анимации'
		},
		control: 'input',
		variants: ['1s', '1.5s', '2s', '2.5s', '3s', '4s', '5s'],
		type: 'text',
		category: 'Main',
		weight: 1
	},
	animFunction: {
		title: {
			en: 'Smooth animation',
			ru: 'Функция сглаживания анимации'
		},
		control: 'input',
		variants: ['linear', 'ease', 'ease-in', 'ease-out', 'ease-in-out', 'step-start', 'step-end'],
		type: 'text',
		category: 'Main',
		weight: 1
	}
};

const pick = (obj, ...keys) => keys.reduce((result, key) => {
	result[key] = obj[key];
	return result;
}, {});

const getAPI = () => {
	if (typeof window !== 'undefined') {
		return window.QAPI || {};
	}

	if (typeof global !== 'undefined') {
		return global.QAPI || {};
	}

	return {};
};

const isPlaceholder = child => child && child.props && child.props.text === 'child placeholder';

const isOverride = child => child && child.props && typeof child.props.slot === 'string' && child.props.slot.length > 0;

function isEmptyChildren(children) {
	const childrenArray = React.Children.toArray(children);
	return !childrenArray.some(child => child && !isPlaceholder(child) && !isOverride(child));
}

const reducer = (state, action) => {
	const {
		type
	} = action;

	switch (type) {
		case 'TOGGLE':
			if (state.destination !== 'none') return state;
			return { ...state,
				destination: state.isOpen ? 'close' : 'open'
			};

		case 'COLLAPSING':
			return { ...state,
				isCollapsing: true
			};

		case 'COLLAPSE_END':
			return { ...state,
				isCollapsing: false,
				isOpen: state.destination === 'open',
				destination: 'none'
			};

		default:
			// eslint-disable-next-line no-console
			console.warn('Unexpected action in Collapse reducer!');
			return state;
	}
};

const pixelTransformer = n => typeof n === 'number' && n !== 0 ? `${n}px` : n;

const Collapse = ({
	minDuration,
	maxDuration,
	duration,
	animFunction,
	...props
}) => {
	const ref = useRef();
	const backupStyles = useRef();
	const {
		override,
		ChildPlaceholder,
		children,
		rest
	} = useOverrides(props, overrides);
	const [{
		destination,
		isOpen,
		isCollapsing
	}, dispatch] = useReducer(reducer, {
		destination: 'none',
		isOpen: false,
		isCollapsing: false
	});
	const mode = useConstructorMode();
	const toggleOpen = useCallback(() => {
		const isDev = getAPI().mode === 'development';
		if (isDev && mode === 'constructor') return;
		dispatch({
			type: 'TOGGLE'
		});
	}, [mode]);
	const collapsedHeight = pixelTransformer(override('Wrapper :close').height) ?? 0;
	const handle = useCallback(e => {
		e?.stopPropagation();
		dispatch({
			type: 'COLLAPSE_END'
		});
		Object.assign(ref.current.style, backupStyles.current);
		ref.current.removeEventListener('transitionend', handle);
	}, []);
	useEffect(() => {
		if (destination === 'none' || !ref.current) return;
		const {
			style
		} = ref.current;

		if (mode === 'constructor') {
			Object.assign(style, {
				transition: 'unset'
			});
			handle();
			return;
		}

		backupStyles.current = pick(style, 'willChange', 'overflow', 'height', 'transition');
		const transition = `height ${duration} ${animFunction}`;
		const expandedHeight = `${ref.current.scrollHeight}px`;
		const [fromHeight, toHeight] = destination === 'open' ? [collapsedHeight, expandedHeight] : [expandedHeight, collapsedHeight];
		dispatch({
			type: 'COLLAPSING'
		});
		requestAnimationFrame(() => {
			style.willChange = 'height';
			style.overflow = 'hidden';
			style.height = fromHeight;
			requestAnimationFrame(() => {
				style.transition = transition;
				style.height = toHeight;
			});
		});
		ref.current.removeEventListener('transitionend', handle);
		ref.current.addEventListener('transitionend', handle);
	}, [animFunction, collapsedHeight, destination, duration, handle, mode]);
	return <Box padding="8px" border="1px solid --color-lightD2" border-radius="4px" {...rest}>
		      
		<Box {...override('Title Container')}>
			        
			<Box {...override('Pre-Button')}>
				          
				<ChildPlaceholder slot="Pre-Button" />
				        
			</Box>
			        
			<Box
				border-radius="80px"
				border="2px #080808 solid"
				width="60px"
				height="80px"
				display="flex"
				align-items="center"
				justify-content="center"
				flex-direction="column"
				flex-wrap="no-wrap"
				padding="22px 24px 22px 24px"
				{...override('Button')}
				onClick={toggleOpen}
			>
				          
				<Image src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/arrow.svg?v=2024-08-31T18:09:11.209Z" display="block" transform={isOpen ? "rotate(-90deg)" : "rotate(90deg)"} width="40px" />
				        
			</Box>
			      
		</Box>
		      
		<Box ref={ref} {...override('Wrapper', !isCollapsing && `Wrapper ${isOpen ? ':open' : ':close'}`)}>
			        
			<Box {...override('Content')}>
				          
				{children}
				          
				{isEmptyChildren(children) && <Placeholder message="Drop content here" />}
				        
			</Box>
			      
		</Box>
		    
	</Box>;
};

Object.assign(Collapse, {
	title: 'Collapse',
	description: {
		en: 'This component allows you to collapse the content smoothly',
		ru: 'Компонент для плавного переключания видимости содержимого с изменением высоты'
	},
	overrides,
	propInfo,
	defaultProps
});
export default Collapse;