import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Text, LinkBox, Box, Icon, Button } from "@quarkly/widgets";
import DropdownClose from "./DropdownClose";
import Dropdown from "./Dropdown";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
import { FiMenu } from "react-icons/fi";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
const defaultProps = {
	"min-width": "100px",
	"background": "url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Header%20Desktop.png?v=2024-08-28T15:04:52.885Z) 0% 0%/cover padding-box,#000",
	"padding": "0px 16px 0px 16px",
	"left": "0px",
	"position": "fixed",
	"right": "0px",
	"z-index": "1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"height": "88px",
			"flex-direction": "row",
			"justify-content": "space-between",
			"align-items": "center",
			"grid-gap": "190px",
			"display": "flex",
			"width": "100%",
			"flex-wrap": "no-wrap",
			"sm-padding": "0px 0px 0px 0px",
			"sm-height": "52px",
			"sm-display": "flex",
			"sm-grid-column-gap": "0px",
			"sm-grid-row-gap": "0px",
			"grid-column-gap": 0,
			"position": "relative",
			"lg-padding": "24px 16px 24px 16px",
			"margin": "0px auto 0px auto",
			"max-width": "1348px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "12px 0",
			"justify-content": "space-between",
			"align-items": "flex-start",
			"flex-direction": "row",
			"sm-align-items": "center",
			"sm-flex-direction": "row",
			"sm-justify-content": "flex-start",
			"lg-width": "50%",
			"lg-justify-content": "flex-start",
			"sm-height": "auto",
			"sm-padding": "0px 0 0px 0",
			"flex": "0 0 33.33%",
			"sm-flex": "0 0 auto",
			"lg-flex": "0 0 100px"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "12px",
			"sm-height": "min-content"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo.svg?v=2024-09-17T17:58:03.987Z",
			"sm-height": "28px",
			"sm-display": "block",
			"height": "50px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "white",
			"line-height": "30px",
			"word-wrap": "break-word",
			"sm-font": "600 15px/24px Epilogue",
			"sm-margin": "0px 0px 0px 0px",
			"font": "600 27px Epilogue",
			"children": "SPhotonix"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"menuPosition": "full",
			"sm-width": "50%",
			"lg-width": "30%",
			"flex": "0 0 66.66%",
			"position": "static",
			"sm-flex": "0 0 auto",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-end",
			"lg-flex-direction": "row-reverse",
			"breakpoint": "lg",
			"lg-position": "relative"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"lg-display": "flex",
			"lg-height": "100%"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"padding": "0px 0px 0px 0px",
			"sm-padding": "10px 0px 0px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"sm-background": "white",
			"lg-background": "white",
			"max-width": "none",
			"max-height": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"sm-font": "normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"sm-margin": "0px 2px 0px 0px",
			"lg-margin": "0px 0px 0px 0px",
			"lg-display": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon :closed",
			"category": "fi",
			"icon": FiMenu,
			"size": "32px",
			"padding": "5px 7px 5px 7px",
			"border-radius": "50px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride4": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"width": "28px",
			"height": "28px",
			"category": "fi",
			"icon": FiMenu,
			"color": "--dark",
			"size": "24px",
			"lg-width": "32px",
			"lg-height": "32px",
			"lg-color": "#ffffff",
			"lg-padding": "1px 1px 1px 1px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride5": {
		"kind": "Override",
		"props": {
			"slot": "Cross",
			"lg-width": "32px",
			"lg-height": "32px",
			"size": "32px",
			"top": "24px",
			"right": "24px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride6": {
		"kind": "Override",
		"props": {
			"slot": "Wrapper",
			"position": "static",
			"lg-position": "relative"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride7": {
		"kind": "Override",
		"props": {
			"slot": "Overlay"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"justify-content": "flex-end",
			"lg-display": "flex",
			"lg-flex-direction": "column",
			"lg-flex-wrap": "no-wrap",
			"lg-align-items": "center",
			"lg-justify-content": "flex-start",
			"lg-width": "100%",
			"lg-height": "100%",
			"lg-grid-row-gap": 0,
			"flex-direction": "row",
			"display": "flex"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"lg-width": "100%",
			"min-height": "100px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"lg-padding": "24px 64px 0px 16px",
			"display": "none",
			"lg-display": "block"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"href": "/index",
			"display": "flex",
			"grid-gap": "12px",
			"lg-align-items": "center",
			"lg-display": "flex",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z",
			"display": "none",
			"lg-height": "28px",
			"lg-display": "block"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"color": "white",
			"font": "600 21px Epilogue",
			"line-height": "30px",
			"word-wrap": "break-word",
			"lg-font": "600 15px/24px Epilogue",
			"lg-margin": "0px 0px 0px 0px",
			"lg-color": "black",
			"children": "SPhotonix"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"height": "48px",
			"justify-content": "flex-start",
			"align-items": "center",
			"grid-gap": "32px",
			"display": "inline-flex",
			"lg-flex-direction": "column",
			"lg-flex-wrap": "no-wrap",
			"lg-align-items": "flex-start",
			"lg-justify-content": "center",
			"lg-height": "min-content",
			"position": "absolute",
			"left": "50%",
			"transform": "translateX(-50%)",
			"lg-width": "100%",
			"lg-position": "static",
			"lg-margin": "0px auto 0px auto",
			"lg-transform": "none",
			"lg-display": "flex",
			"lg-grid-row-gap": "0px",
			"lg-left": "25%"
		}
	},
	"dropdown": {
		"kind": "Dropdown",
		"props": {
			"height": "100%",
			"activationMode": "hover",
			"lg-width": "100%",
			"lg-position": "static"
		}
	},
	"dropdownOverride": {
		"kind": "Override",
		"props": {
			"slot": "Menu",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"flex-direction": "column",
			"flex-wrap": "no-wrap",
			"padding": "0px 0px 12p0pxx 0px",
			"lg-width": "100%",
			"lg-top": "80px",
			"lg-box-shadow": "none",
			"lg-padding": "0px 0px 50px 0px",
			"width": "240px"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"padding": "0px 16px 24px 16px",
			"display": "none",
			"lg-display": "block"
		}
	},
	"dropdownClose": {
		"kind": "DropdownClose",
		"props": {
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"color": "--accentRed",
			"flex-direction": "row",
			"justify-content": "flex-start"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdArrowBack,
			"lg-height": "100%",
			"margin": "0px 6px 0px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"children": "Company"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-width": "0 0 1px 0",
			"border-color": "#D8D8D8",
			"border-style": "solid",
			"hover-color": "--accentRed",
			"href": "/about-us"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--bodyText17Medium",
			"children": "About us"
		}
	},
	"linkBox3": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-style": "solid",
			"border-width": "0 0 1px 0",
			"border-color": "#D8D8D8",
			"hover-color": "--accentRed",
			"href": "/brief-history",
			"display": "none"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--bodyText17Medium",
			"children": "Brief history"
		}
	},
	"linkBox4": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-color": "#D8D8D8",
			"border-width": "0 0 1px 0",
			"border-style": "solid",
			"hover-color": "--accentRed",
			"href": "/team"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--bodyText17Medium",
			"children": "Team"
		}
	},
	"linkBox5": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-width": "0 0 1px 0",
			"border-color": "#D8D8D8",
			"border-style": "solid",
			"hover-color": "--accentRed",
			"href": "/recognition",
			"display": "none"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--bodyText17Medium",
			"children": "Recognition"
		}
	},
	"linkBox6": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 16px 16px",
			"hover-color": "--accentRed",
			"href": "/facilities",
			"display": "none"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--bodyText17Medium",
			"children": "Facilities"
		}
	},
	"dropdownOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Container",
			"color": "white",
			"height": "100%",
			"display": "flex",
			"align-items": "center",
			"lg-width": "100%"
		}
	},
	"linkBox7": {
		"kind": "LinkBox",
		"props": {
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "space-between",
			"lg-display": "flex",
			"lg-flex-wrap": "no-wrap",
			"lg-align-items": "center",
			"lg-hover-color": "--accentRed",
			"font": "--bodyText15Regular",
			"color": "--accentOrange"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"lg-font": "500 17px/26px Epilogue",
			"color": "inherit",
			"children": "Company",
			"font": "20px/24px Epilogue"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdArrowForward,
			"lg-height": "100%",
			"lg-display": "flex",
			"display": "none"
		}
	},
	"dropdown1": {
		"kind": "Dropdown",
		"props": {
			"height": "100%",
			"activationMode": "hover",
			"lg-width": "100%",
			"lg-position": "static"
		}
	},
	"dropdownOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Menu",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"flex-direction": "column",
			"flex-wrap": "no-wrap",
			"padding": "0px 0px 12p0pxx 0px",
			"lg-width": "100%",
			"lg-top": "80px",
			"lg-box-shadow": "none",
			"lg-padding": "0px 0px 50px 0px",
			"width": "260px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"padding": "0px 16px 24px 16px",
			"sm-display": "block",
			"display": "none",
			"lg-display": "block"
		}
	},
	"dropdownClose1": {
		"kind": "DropdownClose",
		"props": {
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"color": "--accentRed",
			"flex-direction": "row",
			"justify-content": "flex-start"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdArrowBack,
			"lg-height": "100%",
			"margin": "0px 6px 0px 0px"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"children": "Technology"
		}
	},
	"linkBox8": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-width": "0 0 1px 0",
			"border-color": "#D8D8D8",
			"border-style": "solid",
			"hover-color": "--accentRed",
			"href": "/data-storage-innovation"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "500 17px/26px Epilogue",
			"children": "Data Storage Innovation"
		}
	},
	"linkBox9": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-style": "solid",
			"border-width": "0 0 1px 0",
			"border-color": "#D8D8D8",
			"hover-color": "--accentRed",
			"href": "/optical-device-fabrication"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "500 17px/26px Epilogue",
			"children": "Optical Device Fabrication"
		}
	},
	"linkBox10": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-color": "#D8D8D8",
			"border-width": "0 0 1px 0",
			"border-style": "solid",
			"hover-color": "--accentRed",
			"display": "none"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "500 17px/26px Epilogue",
			"children": "Team"
		}
	},
	"linkBox11": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 15px 16px",
			"border-width": "0 0 1px 0",
			"border-color": "#D8D8D8",
			"border-style": "solid",
			"hover-color": "--accentRed",
			"display": "none"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "500 17px/26px Epilogue",
			"children": "Recognition"
		}
	},
	"linkBox12": {
		"kind": "LinkBox",
		"props": {
			"font": "400 15px/24px Epilogue",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"align-items": "flex-start",
			"padding": "16px 16px 16px 16px",
			"hover-color": "--accentRed",
			"display": "none"
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "500 17px/26px Epilogue",
			"children": "Facilities"
		}
	},
	"dropdownOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Container",
			"color": "white",
			"height": "100%",
			"display": "flex",
			"align-items": "center",
			"lg-width": "100%"
		}
	},
	"linkBox13": {
		"kind": "LinkBox",
		"props": {
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"color": "inherit",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "space-between",
			"lg-display": "flex",
			"lg-flex-wrap": "no-wrap",
			"lg-align-items": "center",
			"lg-hover-color": "--accentRed",
			"font": "--bodyText15Regular"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"lg-font": "500 17px/26px Epilogue",
			"font": "20px/24px Epilogue",
			"children": "Technology"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdArrowForward,
			"lg-height": "100%",
			"lg-display": "flex",
			"display": "none"
		}
	},
	"linkBox14": {
		"kind": "LinkBox",
		"props": {
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"lg-hover-color": "--accentRed",
			"color": "white",
			"font": "--bodyText15Regular",
			"href": "/memory-crystal",
			"display": "none"
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"lg-color": "inherit",
			"children": <>
				Memory crystal{" "}
			</>
		}
	},
	"linkBox15": {
		"kind": "LinkBox",
		"props": {
			"font": "--bodyText15Regular",
			"word-wrap": "break-word",
			"text-decoration-line": "initial",
			"lg-color": "black",
			"lg-width": "100%",
			"lg-border-color": "#D8D8D8",
			"lg-border-width": "0 0 1px 0",
			"lg-border-style": "solid",
			"lg-font": "500 17px/26px Epilogue",
			"lg-padding": "16px 16px 15px 16px",
			"lg-flex-direction": "row",
			"lg-justify-content": "flex-start",
			"lg-hover-color": "--accentRed",
			"color": "white",
			"href": "/contact"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"lg-color": "inherit",
			"font": "20px/24px Epilogue",
			"color": "inherit",
			"sm-font": "500 17px/26px Epilogue",
			"children": "Contact"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"justify-content": "flex-end",
			"align-items": "center",
			"grid-gap": "32px",
			"display": "flex",
			"lg-display": "flex",
			"lg-flex-direction": "column",
			"lg-flex-wrap": "no-wrap",
			"lg-align-items": "center",
			"lg-justify-content": "flex-end",
			"lg-height": "100%",
			"flex-direction": "row",
			"lg-width": "100%",
			"lg-padding": "16px 16px 16px 16px"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"border-radius": "8px",
			"border": "1px white solid",
			"backdrop-filter": "blur(6px)",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"gap": "10px",
			"display": "inline-flex",
			"background": "none",
			"focus-box-shadow": "none",
			"text-decoration-line": "initial",
			"lg-border-color": "black",
			"lg-color": "black",
			"lg-width": "100%",
			"lg-justify-content": "center",
			"type": "link",
			"href": "/memory-crystal",
			"font": "--bodyText17Regular",
			"children": "Order 5D Memory Crystal™"
		}
	}
};

const Header2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<LinkBox {...override("linkBox")}>
					<Image {...override("image")} />
					<Text {...override("text")} />
				</LinkBox>
			</Box>
			<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
				<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride4")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride5")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride6")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride7")} />
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Box {...override("box4")}>
							<LinkBox {...override("linkBox1")}>
								<Image {...override("image1")} />
								<Text {...override("text1")} />
							</LinkBox>
						</Box>
					</Box>
					<Box {...override("box5")}>
						<Dropdown {...override("dropdown")}>
							<Override {...override("dropdownOverride")}>
								<Box {...override("box6")}>
									<DropdownClose {...override("dropdownClose")}>
										<Icon {...override("icon")} />
										<Text {...override("text2")} />
									</DropdownClose>
								</Box>
								<LinkBox {...override("linkBox2")}>
									<Text {...override("text3")} />
								</LinkBox>
								<LinkBox {...override("linkBox3")}>
									<Text {...override("text4")} />
								</LinkBox>
								<LinkBox {...override("linkBox4")}>
									<Text {...override("text5")} />
								</LinkBox>
								<LinkBox {...override("linkBox5")}>
									<Text {...override("text6")} />
								</LinkBox>
								<LinkBox {...override("linkBox6")}>
									<Text {...override("text7")} />
								</LinkBox>
							</Override>
							<Override {...override("dropdownOverride1")} />
							<LinkBox {...override("linkBox7")}>
								<Text {...override("text8")} />
								<Icon {...override("icon1")} />
							</LinkBox>
						</Dropdown>
						<Dropdown {...override("dropdown1")}>
							<Override {...override("dropdownOverride2")}>
								<Box {...override("box7")}>
									<DropdownClose {...override("dropdownClose1")}>
										<Icon {...override("icon2")} />
										<Text {...override("text9")} />
									</DropdownClose>
								</Box>
								<LinkBox {...override("linkBox8")}>
									<Text {...override("text10")} />
								</LinkBox>
								<LinkBox {...override("linkBox9")}>
									<Text {...override("text11")} />
								</LinkBox>
								<LinkBox {...override("linkBox10")}>
									<Text {...override("text12")} />
								</LinkBox>
								<LinkBox {...override("linkBox11")}>
									<Text {...override("text13")} />
								</LinkBox>
								<LinkBox {...override("linkBox12")}>
									<Text {...override("text14")} />
								</LinkBox>
							</Override>
							<Override {...override("dropdownOverride3")} />
							<LinkBox {...override("linkBox13")}>
								<Text {...override("text15")} />
								<Icon {...override("icon3")} />
							</LinkBox>
						</Dropdown>
						<LinkBox {...override("linkBox14")}>
							<Text {...override("text16")} />
						</LinkBox>
						<LinkBox {...override("linkBox15")}>
							<Text {...override("text17")} />
						</LinkBox>
					</Box>
					<Box {...override("box8")}>
						<Button {...override("button")}></Button>
					</Box>
				</Box>
			</QuarklycommunityKitMobileSidePanel>
		</Box>
		{children}
	</Box>;
};

Object.assign(Header2, { ...Box,
	defaultProps,
	overrides
});
export default Header2;