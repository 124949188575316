import React, { useCallback, useState } from 'react';
import atomize from "@quarkly/atomize";
import { Box, Text, Input } from '@quarkly/widgets';
import { useForm, withForm } from '@quarkly/community-kit/Form/context';
import useFormField from '@quarkly/community-kit/Form/hooks/useFormField';
import { useOverrides } from '@quarkly/components';
const Form = atomize.form();
const overrides = {
	Form: {
		kind: 'Form'
	},
	Text: {
		kind: 'Text'
	},
	'Success Text': {
		props: {
			color: '--green',
			font: '--bodyText17Regular',
			children: 'Your request has been submitted, we will be in touch with you soon.'
		}
	},
	'Error Text': {
		props: {
			color: '--red',
			children: 'Error Text'
		}
	}
};
const propInfo = {};

const MakeForm = ({
	action,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const {
		reset
	} = useForm();
	const onSubmitEvent = useCallback(e => {
		e.preventDefault();
		const button = e.target.querySelector('[type="submit"], [type="button"]');
		button?.setAttribute('disabled', true);
		fetch(action, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: new URLSearchParams(new FormData(e.target)).toString()
		}).then(res => {
			if (res.status !== 200) throw Error;
			setSuccess(true);
			console.log(reset);
			reset();
		}).catch(err => {
			console.error(err);
			setError(true);
		}).finally(() => {
			button?.removeAttribute('disabled');
			setTimeout(() => {
				setSuccess(false);
				setError(false);
			}, 5000);
		});
	}, [action, reset]);
	const onReset = useCallback(e => {
		e.preventDefault();
		reset();
		setError(false);
	}, [reset]);
	return <Box {...rest}>
		      
		<Form {...override('Form')} onSubmit={onSubmitEvent} onReset={onReset}>
			        
			{children}
			        
			{error && <Text {...override('Text', 'Error Text', {
				defaultKey: 'Error Text'
			})} />}
			        
			{success && <Text {...override('Text', 'Success Text', {
				defaultKey: 'Success Text'
			})} />}
			      
		</Form>
		    
	</Box>;
};

const MakeFormWrapped = withForm(MakeForm);
Object.assign(MakeFormWrapped, {
	title: 'Netlify Form',
	description: {},
	propInfo,
	defaultProps: {},
	overrides
});
export default MakeFormWrapped;