import React, { useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Image } from "@quarkly/widgets";
import { Placeholder } from '@quarkly/widgets';
import atomize from '@quarkly/atomize';
const CustomButton = atomize.button({
	effects: {
		disabled: ":disabled"
	}
});
const propInfo = {
	tabsCount: {}
};
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"height": "100%",
	"width": "100%",
	"display": "flex",
	"align-items": "flex-start",
	"justify-content": "center",
	"flex-direction": "column",
	"flex-wrap": "no-wrap",
	"grid-row-gap": "64px"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"align-self": "stretch",
			"height": "auto",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "24px",
			"display": "flex",
			"lg-height": "auto",
			"lg-grid-row-gap": "16px",
			"lg-display": "flex"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"height": "50px",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "28px",
			"display": "inline-flex",
			"lg-width": "100%",
			"lg-display": 'flex',
			"lg-flex-direction": "row",
			"lg-flex-wrap": "no-wrap",
			"lg-align-items": "center",
			"lg-justify-content": "space-between",
			"lg-grid-column-gap": 0,
			"lg-height": "auto"
		}
	},
	"Tab Text :active": {
		"kind": "Text",
		"props": {
			"opacity": 1,
			"color": "#080808",
			"font": "700 42px Epilogue",
			"line-height": "50px",
			"word-wrap": "break-word",
			"margin": "0 0 0 0"
		}
	},
	"Tab Text": {
		"kind": "Text",
		"props": {
			"opacity": 0.2,
			"color": "#080808",
			"font": "700 42px Epilogue",
			"line-height": "50px",
			"word-wrap": "break-word",
			"margin": "0 0 0 0",
			"children": "2000",
			"lg-font": "700 19px/22px Epilogue"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"align-self": "stretch",
			"color": "#212121",
			"font": "400 21px Epilogue",
			"line-height": "32px",
			"word-wrap": "break-word",
			"margin": "0 0 0 0",
			"lg-height": "auto",
			"lg-font": "400 17px/26px Epilogue",
			"children": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"justify-content": "center",
			"align-items": "flex-start",
			"grid-gap": "16px",
			"display": "flex",
			"lg-display": "none"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/66d0640c995d120018f37959/images/arrow.svg?v=2024-08-31T18:09:11.209Z",
			"display": "block",
			"transform": "rotate(180deg)"
		}
	},
	"Button": {
		"kind": "CustomButton",
		"props": {
			"border-radius": "80px",
			"border": "2px white solid",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"grid-gap": "10px",
			"display": "flex",
			"border-color": "#212121",
			"padding": "12px 22px 12px 22px",
			"disabled-opacity": 0.4
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/66d0640c995d120018f37959/images/arrow.svg?v=2024-08-31T18:09:11.209Z",
			"display": "block"
		}
	},
	"Tab Content": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"min-height": "20px"
		}
	}
};

const getInt = (value, defaultValue, validator = () => true) => {
	const parsed = parseInt(value);
	return !Number.isNaN(parsed) && validator(parsed) ? parsed : parseInt(defaultValue);
};

const DateTabs = ({
	tabsCount: tabsCountFromProps,
	...props
}) => {
	const {
		override,
		children,
		ChildPlaceholder,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const tabsCount = getInt(tabsCountFromProps, 1, val => val > 0);
	const [current, setCurrent] = useState(0);
	return <Box {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				{Array(tabsCount).fill().map((_, i) => {
					const isActive = i === current;
					return <Text {...override("Tab Text", isActive && "Tab Text :active", `Tab Text ${i + 1}`, {
						defaultKey: `Tab Text ${i + 1}`
					})} onClick={() => {
						setCurrent(i);
					}} />;
				})}
			</Box>
			<Box {...override("Tab Content", `Tab Content ${current + 1}`, {
				defaultKey: `Tab Content ${current + 1}`
			})}>
				<ChildPlaceholder slot={`Tab Content ${current + 1}`} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<CustomButton {...override("Button")} disabled={current === 0} onClick={() => setCurrent(x => x - 1)}>
				<Image {...override("image")} />
			</CustomButton>
			<CustomButton {...override("Button")} disabled={current === tabsCount - 1} onClick={() => setCurrent(x => x + 1)}>
				<Image {...override("image1")} />
			</CustomButton>
		</Box>
	</Box>;
};

Object.assign(DateTabs, { ...Box,
	defaultProps,
	overrides,
	propInfo
});
export default DateTabs;