import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image, Link, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"facilities"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px" z-index="1">
			<Override slot="linkBox7" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			width="100%"
			height="auto"
			padding-top="120px"
			padding-bottom="64px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			padding="120px 16px 64px 16px"
			md-padding="60px 16px 24px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				grid-gap="16px"
				display="flex"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="center"
					color="#080808"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					sm-font="600 32px/30px Epilogue"
				>
					Facilities
				</Text>
				{"    "}
				<Text
					text-align="center"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="747px"
					max-width="100%"
					margin="0px 0px 0px 0px"
				>
					Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
				</Text>
				{"  "}
			</Box>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="0px 16px 80px 16px"
			flex-direction="column"
			justify-content="center"
			align-items="center"
			grid-gap="100px"
			display="flex"
			md-padding="0px 16px 80px 16px"
		>
			{"  "}
			<Image
				width="1350px"
				src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14:06:27.423Z"
				max-width="100%"
				srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14%3A06%3A27.423Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14%3A06%3A27.423Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14%3A06%3A27.423Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14%3A06%3A27.423Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14%3A06%3A27.423Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14%3A06%3A27.423Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/b.png?v=2024-09-02T14%3A06%3A27.423Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
			/>
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="50px 16px 0px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="50px 16px 0px 16px"
			md-display="flex"
			md-grid-row-gap="32px"
		>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-height="auto"
				md-width="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				{"    "}
				<Box
					min-height="100px"
					md-width="100%"
					md-height="250px"
					flex="1 1 0%"
					height="530px"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13:38:42.374Z"
						md-width="100%"
						md-height="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/a.png?v=2024-09-02T13%3A38%3A42.374Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				{"    "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="inline-flex"
					md-height="auto"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#080808"
							font="700 42px/50px Epilogue"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							Maximize your business potential with SPhotonix, the solution that’s as reliable as 'It Was a Very Good Year.
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
						</Text>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
			<Box align-self="stretch" height="1px" background="#B1B1B1" />
		</Box>
		<Box
			width="100%"
			height="auto"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="40px 16px 0px 16px"
			md-display="flex"
			md-grid-row-gap="32px"
			padding="100px 16px 100px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-width="auto"
				md-height="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				{"    "}
				<Box
					min-height="100%"
					md-width="100%"
					md-height="250px"
					flex="1 1 0%"
					md-order="-1"
					height="530px"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14:09:31.316Z"
						md-height="100%"
						md-width="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14%3A09%3A31.316Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14%3A09%3A31.316Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14%3A09%3A31.316Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14%3A09%3A31.316Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14%3A09%3A31.316Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14%3A09%3A31.316Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/c.png?v=2024-09-02T14%3A09%3A31.316Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				{"    "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="inline-flex"
					md-height="auto"
					order="-1"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#080808"
							font="700 42px Epilogue"
							line-height="50px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							Maximize your business potential with SPhotonix, the solution that’s as reliable as 'It Was a Very Good Year.
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
						</Text>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
		</Box>
		<Box
			width="100%"
			height="auto"
			padding="0px 16px 100px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="100px"
			display="inline-flex"
			md-padding="50px 16px 24px 16px"
			md-display="flex"
			md-grid-row-gap="40px"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="1px"
				background="#B1B1B1"
				width="1348px"
				margin="0px auto 0px auto"
			/>
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="center"
				grid-gap="100px"
				display="flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-width="auto"
				md-height="auto"
				md-grid-row-gap="32px"
				flex-direction="row"
				flex-wrap="no-wrap"
				margin="0px auto 0px auto"
				max-width="1348px"
			>
				{"    "}
				<Box
					min-height="100px"
					md-width="100%"
					md-height="250px"
					flex="1 1 0%"
					height="530px"
				>
					<Image
						flex="1 1 0"
						src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14:17:31.510Z"
						md-height="100%"
						md-width="100%"
						object-fit="cover"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14%3A17%3A31.510Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14%3A17%3A31.510Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14%3A17%3A31.510Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14%3A17%3A31.510Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14%3A17%3A31.510Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14%3A17%3A31.510Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Rectangle%204633.png?v=2024-09-02T14%3A17%3A31.510Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
				</Box>
				{"    "}
				<Box
					flex="1 1 0%"
					align-self="stretch"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					grid-gap="64px"
					display="inline-flex"
					md-height="auto"
				>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						md-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#080808"
							font="700 42px Epilogue"
							line-height="50px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="700 21px/28px Epilogue"
						>
							Maximize your business potential with SPhotonix, the solution that’s as reliable as 'It Was a Very Good Year.
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							color="#212121"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0 0 0 0"
							md-height="auto"
							md-font="400 17px/26px Epilogue"
						>
							Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl. In metus morbi ipsum mattis risus platea.
						</Text>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
		</Box>
		<Box
			width="100%"
			height="1263px"
			padding-left="190px"
			padding-right="190px"
			padding-top="132px"
			padding-bottom="132px"
			background="url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/blogs-bg.png?v=2024-08-18T10:10:26.882Z) 0% 0%/cover scroll"
			flex-direction="column"
			justify-content="flex-start"
			align-items="center"
			grid-gap="72px"
			display="none"
			sm-padding="60px 16px 60px 16px"
			sm-height="auto"
			padding="132px 190px 132px 190px"
			sm-display="none"
		>
			<Box
				width="1348px"
				height="210px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="64px"
				display="inline-flex"
				sm-width="100%"
			>
				{"  "}
				<Box
					align-self="stretch"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="160px"
					display="inline-flex"
				>
					{"    "}
					<Box
						flex="1 1 0"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="inline-flex"
						sm-width="100%"
					>
						{"      "}
						<Text
							color="#F44450"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="18px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-display="none"
						>
							Key takeaway
						</Text>
						{"      "}
						<Box
							align-self="stretch"
							height="168px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="28px"
							display="flex"
							sm-width="100%"
							sm-height="auto"
						>
							{"        "}
							<Text
								width="789px"
								color="white"
								font="600 72px Epilogue"
								line-height="76px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="600 28px/32px Epilogue"
								sm-width="100%"
							>
								Blogs
							</Text>
							{"        "}
							<Box
								align-self="stretch"
								justify-content="flex-start"
								align-items="flex-end"
								grid-gap="28px"
								display="inline-flex"
							>
								{"                    "}
								<Text
									flex="1 1 0"
									opacity={0.5}
									color="white"
									font="400 21px Epilogue"
									line-height="32px"
									word-wrap="break-word"
									margin="0px 0px 0px 0px"
									sm-width="100%"
								>
									SPhotonix executives and influencers bring insights          and opinions from the world’s largest compute ecosystem.
								</Text>
								<Box
									justify-content="center"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									sm-display="none"
								>
									{"                        "}
									<Box
										padding-left="24px"
										padding-right="24px"
										padding-top="22px"
										padding-bottom="22px"
										transform-origin="0 0"
										opacity={0.4}
										border-radius="80px"
										border="2px white solid"
										justify-content="flex-start"
										align-items="flex-start"
										grid-gap="10px"
										display="flex"
									>
										<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/arrow.svg?v=2024-08-18T10:15:04.732Z" display="block" />
										{"                          "}
									</Box>
									<Box
										padding-left="24px"
										padding-right="24px"
										padding-top="22px"
										padding-bottom="22px"
										border-radius="80px"
										border="2px white solid"
										justify-content="flex-start"
										align-items="flex-start"
										grid-gap="10px"
										display="flex"
									>
										<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/arrow.svg?v=2024-08-18T10:15:04.732Z" display="block" transform="rotate(180deg)" />
									</Box>
									{"          "}
								</Box>
								{"        "}
							</Box>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
			</Box>
			<Box
				width="1348px"
				height="717px"
				justify-content="flex-start"
				align-items="center"
				grid-gap="2px"
				display="flex"
				sm-width="100%"
				sm-display="flex"
				sm-flex-direction="column"
				sm-flex-wrap="no-wrap"
				sm-height="auto"
				flex-direction="row"
				flex-wrap="no-wrap"
			>
				{"      "}
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13:16:00.489Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring-1.png?v=2024-08-31T13%3A16%3A00.489Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Cloud-Scale Archival Storage Using Ultrafast Laser Nanostructuring
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Recent progress in ultrafast laser nanostructuring enables high-density multidimensional volumetric data writing. With exceptional media longevity, this could transform archival cloud storage.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					flex="1 1 30%"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13:06:17.830Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								The tiny glass blocks that can preserve your data for centuries
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								For years governments, hospitals and families have had to use frail magnetic storage for their most important data. Now, scientists have an alternative — that lasts for ever.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					flex="1 1 30%"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12:51:49.830Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/Cloud-Scale%20Archival%20Storage%20Using%20Ultrafast%20Laser%20Nanostructuring.png?v=2024-08-31T12%3A51%3A49.830Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Cloud-Scale Archival Storage Using Ultrafast Laser Nanostructuring
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Recent progress in ultrafast laser nanostructuring enables high-density multidimensional volumetric data writing. With exceptional media longevity, this could transform archival cloud storage.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="inline-flex"
			md-display="flex"
			md-padding="56px 16px 24px 16px"
			md-height="min-content"
			md-grid-row-gap="64px"
			padding="100px 16px 24px 16px"
			background="white"
		>
			{"    "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="80px"
				display="inline-flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-grid-row-gap="24px"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					flex="1 1 0"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="inline-flex"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					md-height="fit-content"
					md-align-items="center"
					md-display="flex"
					md-flex-direction="column"
					md-justify-content="center"
					md-flex-wrap="no-wrap"
					md-grid-column-gap="0px"
					md-grid-row-gap="8px"
					md-max-width="460px"
				>
					{"      "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
					>
						{"        "}
						<Text
							color="#080808"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px"
							md-font="600 28px/32px Epilogue"
							md-width="100%"
							text-fill-color="transparent"
							style={{
								"-webkit-text-fill-color": "transparent"
							}}
							background="linear-gradient(96.04deg, #080808 -0.01%, #FC0D1D 42.34%)"
							background-clip="text"
							max-width="100%"
							width="350px"
						>
							Get in touch...
						</Text>
						{"      "}
					</Box>
					{"      "}
					<Text
						align-self="stretch"
						opacity={0.8}
						color="#080808"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-width="100%"
						md-font="400 17px/26px Epilogue"
					>
						<Strong>
							<Link href="mailto:info@sphotonix.com?Web Enquiry: ">
								info@sphotonix.com
							</Link>
						</Strong>
					</Text>
					{"    "}
				</Box>
				{"    "}
				<Components.MakeForm
					md-width="100%"
					max-width="460px"
					sm-max-width="100%"
					action="https://hook.eu2.make.com/1rbbfu2qo4r932bgoqqy8wdizju2pxtr"
					method="post"
				>
					<Override
						slot="Form"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="inline-flex"
					/>
					<Override
						slot="Content"
						display="flex"
						flex-direction="column"
						flex-wrap="no-wrap"
						grid-row-gap="16px"
					/>
					<Override slot="Error Text" />
					<Override slot="Success Text" font="--bodyText17Regular" />
					{"      "}
					<Text
						align-self="stretch"
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-font="400 17px/26px Epilogue"
					>
						Stay ahead with the latest breakthroughs in optical nanotechnology! Discover how SPhotonix is revolutionizing data storage and optical innovation. Join our community for exclusive insights and updates!
					</Text>
					{"      "}
					<Components.MfInput name="name" type="text" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Name"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					<Components.MfInput name="email" type="email" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Email"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					{"      "}
					<Button
						width="100%"
						padding-left="24px"
						padding-right="24px"
						padding-top="16px"
						padding-bottom="16px"
						background="#EB1E1E"
						border-radius="8px"
						grid-gap="10px"
						color="white"
						font="600 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						text-align="center"
						type="submit"
					>
						Subscibe to Newsletter
					</Button>
					{"    "}
				</Components.MakeForm>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				md-display="flex"
				md-grid-row-gap="32px"
				md-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					align-self="stretch"
					height="1px"
					background="#B1B1B1"
					md-width="100%"
					md-height="1px"
				/>
				{"    "}
				<Box
					align-self="stretch"
					justify-content="space-between"
					align-items="center"
					grid-gap="32px"
					display="flex"
					md-width="100%"
					md-height="fit-content"
					md-display="flex"
					md-flex-direction="row"
					md-flex-wrap="wrap"
					md-align-content="space-around"
					md-align-items="center"
					md-justify-content="space-between"
					flex-direction="row"
					md-grid-row-gap="16px"
				>
					{"      "}
					<Text
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
					>
						©2024 SPhotonix. All rights reserved.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="center"
						grid-column-gap="32px"
						md-order="-1"
					>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Privacy Policy
						</Text>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Cookies
						</Text>
					</Box>
					{"                "}
				</Box>
				{"  "}
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});