import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text } from "@quarkly/widgets";
const defaultProps = {
	"align-self": "stretch",
	"color": "#F44450",
	"font": " 500 16px Epilogue",
	"text-transform": "uppercase",
	"line-height": "20px",
	"letter-spacing": "0.80px",
	"word-wrap": "break-word",
	"sm-font": "500 13px/14px Epilogue",
	"sm-margin": "0px 0px 16px 0px",
	"children": "INNOVATION"
};
const overrides = {};

const Innovation = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Text {...rest}>
		{children}
	</Text>;
};

Object.assign(Innovation, { ...Text,
	defaultProps,
	overrides
});
export default Innovation;