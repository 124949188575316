import React from "react";
import { useOverrides } from "@quarkly/components";
import { Box } from "@quarkly/widgets";
import atomize from "@quarkly/atomize";
import { Stream } from '@cloudflare/stream-react';
const overrides = {
	"StreamVideo": {
		kind: "Box",
		props: {}
	}
};

const StreamComp = ({
	src,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides);
	return <Box {...rest}>
		   
		<Stream
			autoplay
			src={src}
			controls
			width="100%"
			height="100%"
		/>
		{children}
	</Box>;
};

Object.assign(StreamComp, {
	title: 'StreamComp',
	description: {
		en: 'Stream Video'
	},
	propInfo: {
		// paste here props description for your component
		src: {
			title: 'Your video id',
			control: 'input',
			category: 'Main',
			weight: 1
		}
	},
	defaultProps: {
		src: '5286c8470a488194b9451ab4cf8692a1'
	},
	overrides
});
export default StreamComp;